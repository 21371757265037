<template>
  <div class="ParentsCorner">
    <p class="the">{{ $t('ParentsCorner.title1') }}</p>
    <div class="coach-profile">
      <div>
        <span @click="createJob()">{{ $t('ParentsCorner.post') }}</span>
      </div>
      <p class="con">{{ $t('ParentsCorner.description1') }}</p>
    </div>
    <div class="banner">
      <div class="img-box">
        <img width="100%" height="100%" class="img" src="https://df7ocie544p6c.cloudfront.net/images/b3-may-17-22.png" alt="" />
      </div>
    </div>
    <div class="opportunities">
      <a class="click-hover" @click="gotoUrl('/coach-connect/coach-profiles')">{{ $t('ParentsCorner.explore') }}</a>
      <p class="con-1">{{ $t('ParentsCorner.description2') }}</p>
    </div>
    <div class="banner-1">
      <div class="img-box">
        <img width="100%" height="100%" class="img" src="https://df7ocie544p6c.cloudfront.net/images/b4-may-17-22.png" alt="" />
      </div>
    </div>
    <div class="platform-con">
      <div class="platform">
        <p class="title">{{ $t('ParentsCorner.online') }}</p>
        <img width="90%" src="https://df7ocie544p6c.cloudfront.net/images/banner1.jpg" />
        <div class="description">
          <a class="sports click-hover">{{ $t('ParentsCorner.tit_1') }}</a>
          <p>{{ $t('ParentsCorner.desc1') }}</p>

          <div class="button">
            <router-link :to="{ path: '/inspire/online-courses' }"
              ><el-button>{{ $t('ParentsCorner.box1') }}</el-button></router-link
            >
          </div>
          <p></p>

          <a class="sports click-hover">{{ $t('ParentsCorner.tit_2') }}</a>
          <p>{{ $t('ParentsCorner.desc2') }}</p>

          <div class="button">
            <router-link :to="{ path: '/safeguarding' }"
              ><el-button>{{ $t('ParentsCorner.box2') }}</el-button></router-link
            >
          </div>
        </div>
      </div>
      <div class="platform">
        <p class="title">{{ $t('ParentsCorner.verify') }}</p>
        <img width="90%" src="https://df7ocie544p6c.cloudfront.net/images/corner5-05-07-22.jpg" />
        <div class="description">
          <p>{{ $t('ParentsCorner.desc3') }}</p>
          <p>{{ $t('ParentsCorner.desc4') }}</p>
          <div class="button">
            <router-link :to="{ path: '/coach-connect/how-verify' }"
              ><el-button>{{ $t('ParentsCorner.box3') }}</el-button></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    gotoUrl(url) {
      this.$router.push(url)
    },
    open() {
      this.$alert(this.$t('Tools.warningtext'), this.$t('Tools.warningtitle'), {
        confirmButtonText: 'OK',
        type: 'warning',
      })
    },
    checkLogin() {
      let token = window.sessionStorage.getItem('token')
      return token == null ? false : true
    },
    createJob() {
      let isLogin = this.checkLogin()
      if (!isLogin) {
        this.open()
      } else {
        this.$router.push('/coach-connect/create-job')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.coach-profile {
  text-align: left;
  background: #d7ece6;
  padding: 50px;
  font-size: 35px;
  color: black;
  font-weight: bold;

  span {
    border-bottom: 1px solid;
    color: black;
    cursor: pointer;
  }
}
.the {
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
  margin-top: 20px;
  margin-bottom: 10px;
}
.con {
  background: #d7ece6;
  text-align: left;
  font-weight: 400;
  color: black;
  font-size: 22px;
  margin-top: 10px;
}
.con-1 {
  background: #d7ece6;
  text-align: right;
  font-weight: 400;
  color: black;
  font-size: 22px;
  margin-top: 10px;
}
.opportunities {
  margin-top: 50px;
  text-align: right;
  background: #d7ece6;
  padding: 50px;
  font-size: 35px;
  color: black;
  font-weight: bold;
  a {
    border-bottom: 1px solid;
    color: black;
  }
}
.platform-con {
  display: flex;
  flex-direction: row;
  margin-top: 100px;
  background: whitesmoke;

  .platform {
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: center;
    img {
      margin-top: 40px;
    }
    a.sports {
      color: #4fe5bb;
      text-decoration: underline;
      font-weight: 900;
    }
    p {
      margin-bottom: 10px;
    }
    .description {
      font-size: 25px;
      padding: 80px;
    }
  }
  a.links {
    text-decoration: underline;
    display: flex;
    color: #0f56cc;
    flex-direction: column;
  }
  .title {
    background: #d7ece6;
    width: 730px;
    font-weight: bold;
    text-align: center;
    font-size: 30px;
    color: black;
    border-radius: 15px;
    padding: 26px;
  }
  .platform {
    margin: 20px;
    border-radius: 15px;
    background: white;
  }
  .el-button {
    background: #4fe5bb;
  }
}
</style>
